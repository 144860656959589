import React, { Fragment } from "react"
import SignInForm from "../components/sign-in-form/SignInForm"
import Layout from "../components/layout"
import BecomeADesigner from "../components/BecomeADesigner/BecomeADesigner"
import { navigate } from "gatsby"
import SEO from "../components/seo"
const checkRightPageToLoad = params => {
  var authUser = ""
  if (typeof window !== "undefined") {
    authUser = localStorage.getItem("authUser")
  }
  if (authUser) {
    // If logged in, redirect to the app become a trip designer page.
    navigate(`/app/become-a-trip-designer`)
    return null
  } else return <BecomeADesigner />
}
const BecomeADesignerWithLayout = () => {
  return <Fragment>{checkRightPageToLoad()}</Fragment>
}

export default () => (
  <Layout>
    <SEO
      pageTitle="Become a Travel Designer"
      description="If travel is all your think about and love travel research then join voyayge as a travel designer today."
    />
    <BecomeADesignerWithLayout />
  </Layout>
)
